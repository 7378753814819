<template>
  <sw-tabs-modern
    v-if="router.currentRoute.params.threadId ? thread : true"
    lazy
    :tabs="filteredTabs"
    :thread="thread"
    pills
    fill
    url
    :base-url="getBaseUrl"
    @change-show="changeShowTab"
  />

  <b-spinner
    v-else
    type="grow"
    small
    class="my-50 mx-auto"
    variant="primary"
  />
</template>

<script>
import Timeline from '@/pages/GlobalTimeline.vue'
import ContactDetails from '@/views/core/singleContact/tabs/ContactDetails.vue'
import Applications from '@/views/core/singleContact/tabs/Applications.vue'
import Campaigns from '@/views/core/singleContact/tabs/Campaigns.vue'
import Checklist from '@/views/core/singleContact/tabs/Checklist.vue'
import Tasks from '@/pages/contact/tabs/TasksExpand.vue'
// import Documents from '@/pages/contact/tabs/Documents.vue'
import Documents from '@/views/core/singleContact/tabs/Documents.vue'
import Orders from '@/pages/contact/tabs/OrdersExpand.vue'
import LoyaltyPoints from '@/pages/contact/tabs/LoyaltyPointsExpand.vue'
import MarketingPlans from '@/pages/contact/tabs/MarketingPlansExpand.vue'
import Agreements from '@/pages/contact/tabs/AgreementsExpand.vue'
import Payments from '@/pages/contact/tabs/PaymentsExpand.vue'
import { mapGetters } from 'vuex'
import Offers from '@/pages/contact/tabs/OffersExpand.vue'
import Projects from '@/pages/contact/tabs/ProjectsExpand.vue'
import Email from '@/pages/contact/tabs/EmailExpand.vue'
import Calls from '@/pages/contact/tabs/CallsExpand.vue'
import Complaints from '@/pages/contact/tabs/ComplaintsExpand.vue'
import router from '@/router'
import * as permissions from '@/helpers/permissions'
import * as viewPermissions from '@/helpers/viewPermissions'
import axiosIns from '@/libs/axios'
import Polls from '../../../views/core/singleContact/tabs/Polls.vue'

export default {
  data: () => ({
    router,

    tabs: [
      {
        title: 'app.elements.Timeline',
        description: 'app.elements.Timeline',
        component: Timeline,
        key: 'timeline',
        icon: 'GitPullRequestIcon',
        show: true,
        meta: {
          componentProps: { 'hide-filters': true },
          requiredViews: [],
          requiredPermissions: [permissions.CONTACT_THREAD_TIMELINE_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ASSIGNED],
          requiredModules: [],
          requiredParams: ['threadId'],
        },
      },
      {
        title: 'ContactDetails',
        description: 'ContactDetails',
        component: ContactDetails,
        key: 'contactdetails',
        icon: 'UserIcon',
        show: true,
        meta: {
          requiredViews: [],
          requiredPermissions: [],
          requiredModules: [],
        },
      },
      {
        title: 'Applications',
        description: 'Applications',
        component: Applications,
        key: 'applications',
        icon: 'ArchiveIcon',
        show: true,
        meta: {
          requiredViews: [],
          requiredPermissions: [],
          requiredModules: [],
        },
      },
      {
        title: 'Campaigns',
        description: 'Campaigns',
        component: Campaigns,
        key: 'campaigns',
        icon: 'SendIcon',
        show: true,
        meta: {
          requiredViews: [],
          requiredPermissions: [],
          requiredModules: ['campaignModule'],
        },
      },
      {
        title: 'Email',
        description: 'Email',
        component: Email,
        key: 'email',
        icon: 'MailIcon',
        show: false,
        meta: {
          requiredViews: [viewPermissions.SHOW_CONTACT_CARD_EMAILS],
          requiredPermissions: [permissions.CONTACT_THREAD_MAIL_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ASSIGNED],
          requiredModules: [],
          requiredParams: ['threadId'],
        },
      },
      {
        title: 'Checklist',
        description: 'Checklist',
        component: Checklist,
        key: 'checklist',
        icon: 'CheckSquareIcon',
        show: false,
        meta: {
          requiredViews: [viewPermissions.SHOW_CONTACT_CARD_CHECKLISTS],
          requiredPermissions: [permissions.CONTACT_THREAD_CHECKLIST_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ASSIGNED],
          requiredModules: [],
          requiredParams: ['threadId'],
        },
      },
      {
        title: 'Tasks',
        description: 'Tasks',
        component: Tasks,
        key: 'tasks',
        icon: 'ListIcon',
        show: true,
        meta: {
          requiredViews: [
            viewPermissions.SHOW_CONTACT_CARD_TASKS,
          ],
          requiredPermissions: [
            permissions.CONTACT_THREAD_TASK_VIEW_ALL, permissions.CONTACT_THREAD_TASK_VIEW_STRUCTURE, permissions.CONTACT_THREAD_TASK_VIEW_ASSIGNED, permissions.CONTACT_THREAD_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ASSIGNED,
          ],
          requiredModules: [],
          requiredParams: ['threadId'],
        },
      },
      {
        title: 'Documents',
        description: 'Documents',
        component: Documents,
        key: 'documents',
        icon: 'HardDriveIcon',
        show: false,
        meta: {
          requiredViews: [
            viewPermissions.SHOW_CONTACT_CARD_DOCUMENTS,
          ],
          requiredPermissions: [
            permissions.CONTACT_THREAD_TASK_VIEW_ASSIGNED,
            permissions.CONTACT_THREAD_TASK_VIEW_STRUCTURE,
            permissions.CONTACT_THREAD_TASK_VIEW_ALL,
            permissions.CONTACT_THREAD_NOTE_VIEW_SHARED,
            permissions.CONTACT_THREAD_NOTE_VIEW_SELF,
            permissions.CONTACT_THREAD_NOTE_VIEW_ALL,
            permissions.CONTACT_THREAD_TASK_VIEW_ASSIGNED,
            permissions.CONTACT_THREAD_TASK_VIEW_ALL,
            permissions.CONTACT_THREAD_MAIL_VIEW_ALL,
            permissions.CONTACT_THREAD_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ASSIGNED,
          ],
          requiredModules: [],
          requiredParams: ['threadId'],
        },
      },

      {
        title: 'Offers',
        description: 'Offers',
        component: Offers,
        key: 'offers',
        icon: 'hi-badge-check',
        show: true,
        meta: {
          requiredViews: [viewPermissions.SHOW_CONTACT_CARD_OFFERS],
          requiredPermissions: [permissions.CONTACT_THREAD_OFFER_VIEW_ALL, permissions.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, permissions.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF, permissions.CONTACT_THREAD_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ASSIGNED],
          requiredModules: ['offerModule'],
          requiredParams: ['threadId'],
        },
      },
      {
        title: 'Orders',
        description: 'Orders',
        component: Orders,
        key: 'orders',
        icon: 'ShoppingBagIcon',
        show: true,
        meta: {

          requiredViews: [viewPermissions.SHOW_CONTACT_CARD_ORDERS],
          requiredPermissions: [permissions.CONTACT_THREAD_ORDER_VIEW_ALL, permissions.CONTACT_THREAD_ASSIGNED_ORDER_VIEW_ALL, permissions.CONTACT_THREAD_ASSIGNED_ORDER_VIEW_SELF, permissions.CONTACT_THREAD_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ASSIGNED],
          requiredModules: ['orderModule'],
          requiredParams: ['threadId'],
        },
      },
      {
        title: 'LoyaltyPoints',
        description: 'LoyaltyPoints',
        component: LoyaltyPoints,
        key: 'loyaltypoints',
        icon: 'StarIcon',
        show: false,
        meta: {

          requiredViews: [viewPermissions.SHOW_CONTACT_CARD_LOYALTY_POINTS],
          requiredPermissions: [
            permissions.LOYALTY_PLAN_POINT_VIEW_ALL,
            permissions.LOYALTY_PLAN_POINT_VIEW_SELF,
            permissions.LOYALTY_PLAN_POINT_VIEW_STRUCTURE,
            permissions.LOYALTY_PLAN_POINT_VIEW_STRUCTURE_FIRST,
            permissions.CONTACT_THREAD_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ASSIGNED,
          ],
          requiredModules: ['loyaltyModule'],
          requiredParams: ['threadId'],
        },
      },
      {
        title: 'MarketingPlans',
        description: 'MarketingPlans',
        component: MarketingPlans,
        key: 'marketingplans',
        icon: 'HexagonIcon',
        show: false,
        meta: {
          requiredViews: [viewPermissions.SHOW_CONTACT_CARD_LOYALTY_POINTS,
          ],
          requiredPermissions: [permissions.CONTACT_THREAD_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ASSIGNED],
          requiredModules: ['marketingModule'],
          requiredParams: ['threadId'],
        },
      },
      {
        title: 'ranks.sections.agreements',
        description: 'ranks.sections.agreements',
        component: Agreements,
        key: 'agreements',
        icon: 'FileTextIcon',
        show: false,
        meta: {

          requiredViews: [viewPermissions.SHOW_CONTACT_CARD_AGREEMENTS],
          requiredPermissions: [permissions.CONTACT_THREAD_AGREEMENT_VIEW_ALL, permissions.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL, permissions.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF],
          requiredModules: ['agreementModule'],
        },
      },
      {
        title: 'Polls',
        description: 'Polls',
        component: Polls,
        key: 'polls',
        icon: 'EditIcon',
        show: false,
        meta: {

          requiredViews: [viewPermissions.SHOW_CONTACT_CARD_POLLS],
          requiredPermissions: [permissions.CONTACT_THREAD_POLL_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ASSIGNED],
          requiredModules: ['pollModule'],
          requiredParams: ['threadId'],
        },
      },
      {
        title: 'Payments',
        description: 'Payments',
        component: Payments,
        key: 'payments',
        icon: 'DollarSignIcon',
        show: false,
        meta: {
          requiredViews: [
            viewPermissions.SHOW_CONTACT_CARD_PAYMENTS,
          ],
          requiredPermissions: [
            permissions.CONTACT_THREAD_PAYMENT_VIEW_ALL,
            permissions.CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_ALL,
            permissions.CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_SELF,
            permissions.CONTACT_THREAD_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ASSIGNED,
          ],
          requiredModules: ['paymentModule'],
          requiredParams: ['threadId'],
        },
      },
      {
        title: 'Projects',
        description: 'Projects',
        component: Projects,
        key: 'projects',
        icon: 'BookmarkIcon',
        show: false,
        meta: {
          requiredViews: [
            viewPermissions.SHOW_CONTACT_CARD_PROJECTS,
          ],
          requiredPermissions: [
            permissions.PROJECT_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ASSIGNED,
          ],
          requiredModules: [

            'projectModule',
          ],
          requiredParams: ['threadId'],
        },
      },
      {
        title: 'Complaints',
        description: 'Complaints',
        component: Complaints,
        key: 'complaints',
        icon: 'FrownIcon',
        show: false,
        meta: {
          requiredViews: [],
          requiredPermissions: [
            permissions.CONTACT_THREAD_COMPLAINT_VIEW_ALL,
            permissions.CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_ALL,
            permissions.CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_SELF,
            permissions.CONTACT_THREAD_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ASSIGNED,
          ],
          requiredModules: ['complaintModule'],
          requiredParams: ['threadId'],
        },
      },
      {
        title: 'Calls',
        description: 'Calls',
        component: Calls,
        key: 'calls',
        icon: 'PhoneIcon',
        show: false,
        meta: {
          requiredViews: [],
          requiredPermissions: [permissions.PHONE_CALLS_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ALL, permissions.CONTACT_THREAD_VIEW_ASSIGNED],
          requiredModules: ['callModule'],
          requiredParams: ['threadId'],
        },
      },
    ],
  }),
  computed: {
    ...mapGetters({
      thread: 'contact/thread',
      currentUser: 'auth/getCurrentUser',
    }),

    filteredTabs() {
      return (this.tabs || [])
        .filter(tab => !tab?.meta?.requiredViews?.length || this.checkRequiredViewPermissions(tab.meta.requiredView || []))
        .filter(tab => !tab?.meta?.requiredModules?.length || this.checkRequiredModule(tab.meta.requiredModules || []))
        .filter(tab => !tab?.meta?.requiredPermissions?.length || this.checkRequiredPermissions(tab.meta.requiredPermissions || []))
        .filter(tab => !tab?.meta?.requiredParams?.length || !tab.meta.requiredParams.find(param => !router.currentRoute.params[param]))
    },
    getBaseUrl() {
      const { contactId, threadId } = router.currentRoute.params

      return `/new_contact/${contactId}/thread/${threadId}`
    },
  },
  mounted() {
    if (this.currentUser.configTables && this.currentUser.configTables['thread-tabs']) {
      const tabs = JSON.parse(this.currentUser.configTables['thread-tabs'])
      tabs.forEach(tab => {
        const index = this.tabs.findIndex(e => e.key === tab.key)
        if (index > -1) {
          this.$set(this.tabs[index], 'show', tab.show)
        }
      })
    }
  },
  methods: {
    async changeShowTab({ tab, value }) {
      const index = this.tabs.findIndex(e => e.key === tab.key)
      if (index > -1) {
        this.$set(this.tabs[index], 'show', value)

        const payload = this.tabs.map(e => ({ key: e.key, show: e.show }))
        const newConfig = { ...(this.currentUser.configTables || {}), 'thread-tabs': JSON.stringify(payload) }
        this.currentUser.configTables = newConfig
        await axiosIns.patch(`1/users/${this.currentUser.id}/profile`, { id: this.currentUser.id, configTables: newConfig })
      }
    },
  },
}
</script>
