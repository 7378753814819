<template>
  <div v-if="protectActiveFunnelPoint && router.currentRoute.params.threadId">
    <sw-breadcrumbs
      v-if="!loading && thread"
      :items="funnelPoints"
      class="mt-50"
      :change="changeActiveFunnelPoint"
    />

    <b-spinner
      v-else
      type="grow"
      small
      class="my-50 mx-auto"
      variant="primary"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  ADD_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS,
  CHANGE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS_ACTIVE,
  GET_CURRENT_CONTACT_FUNNEL_POINTS,
  GET_FULL_CURRENT_CONTACT_THREAD_LIST,
} from '@/@constants/mutations'
import * as $roles from '@/helpers/permissions'
import Ripple from 'vue-ripple-directive'
import * as $viewRanks from '@/helpers/viewPermissions'
import { VBTooltip } from 'bootstrap-vue'
import router from '@/router'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data: () => ({
    router,

    loading: false,
    funnelPoints: [],
  }),
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',
      contact: 'contact/contact',
      thread: 'contact/thread',
    }),

    protectActiveFunnelPoint() {
      return this.checkRequiredPermissions([
        $roles.CONTACT_THREAD_EDIT_ALL,
        {
          role: $roles.CONTACT_THREAD_EDIT_ASSIGNED, data: this.thread, userId: this.user.id, field: 'assignedUsers',
        },
        {
          role: $roles.CONTACT_THREAD_EDIT_SELF_ASSIGNED, data: this.thread, userId: this.user.id, field: 'createdBy',
        },
      ]) && this.checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_STATUS_LIST])
    },
  },
  watch: {
    async contact() { if (this.protectActiveFunnelPoint) await this.loadThreadList() },
    async thread() { if (this.protectActiveFunnelPoint) await this.loadFunnelPointList() },
  },
  async mounted() {
    if (this.protectActiveFunnelPoint) {
      if (this.contact) await this.loadThreadList()
      if (this.thread) await this.loadFunnelPointList()
    }
  },
  methods: {
    ...mapActions({
      getFullThreadList: `contact/${GET_FULL_CURRENT_CONTACT_THREAD_LIST}`,
      getCurrentFunnelPoints: `contact/${GET_CURRENT_CONTACT_FUNNEL_POINTS}`,
      changeActiveFunnelProcess: `contact/${CHANGE_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS_ACTIVE}`,
      addThreadFunnelProcess: `contact/${ADD_CURRENT_CONTACT_THREAD_FUNNEL_PROCESS}`,
    }),
    ...mapMutations({
      changeActiveThread: 'contact/changeActiveThread',
    }),

    async loadThreadList() {
      this.loading = true

      try {
        this.threadList = await this.getFullThreadList()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      this.loading = false
    },
    async loadFunnelPointList() {
      this.loading = true

      try {
        this.funnelPoints = await this.getCurrentFunnelPoints()
      } catch (err) {
        if (err.message === 'No funnel process') {
          this.funnelPoints = []
          this.loading = false
          return
        }

        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }

      this.loading = false
    },
    changeActiveFunnelPoint(funnelPoint) {
      return new Promise((res, rej) => {
        const payload = {
          id: this.thread.contactThreadFunnelProcessSelected.id,
          funnelPointId: funnelPoint.id,
        }

        this.changeActiveFunnelProcess(payload)
          .then(() => res())
          .catch(err => rej(err))
      })
    },
    async closeFunnel(create = false, open = false, targetId = null) {
      const status = this.closeNote.status !== 'REJECTED' ? 'CLOSED_POSITIVE' : 'CLOSED_NEGATIVE'
      const threadId = this.thread?.id
      const openedId = targetId || this.thread?.openedId

      try {
        let payload = {
          status,
          statusNote: this.closeNote.note,
          contactThreadStatusReason: this.getObjectId(this.closeNote.reason),
          id: openedId,
        }

        if (create) {
          this.showOpenProcess = true
          return
        }

        if (open) {
          payload = {
            status: 'OPEN',
            statusNote: null,
            contactThreadStatusReason: null,
            id: openedId,
          }
        }

        await this.addThreadFunnelProcess(payload)

        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        const reason = this.reasons.find(r => r.id === payload.contactThreadStatusReason)

        this.thread.status = payload.status
        this.thread.statusNote = payload.statusNote
        this.thread.contactThreadStatusReason = open ? null : reason
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.selectSingleThread(threadId)
        this.showCloseNote = false
        this.closeNote = {
          status: 'CLOSED_POSITIVE',
          reason: '',
          note: '',
        }
      }
    },
    async changeActiveFunnel(funnel) {
      const isCurrentOpen = this.thread.contactThreadFunnelProcesses.find(t => t.status === 'OPEN' && t.contactThreadFunnelType.id === funnel.id)
      const isTargetExist = this.thread.contactThreadFunnelProcesses.find(t => t.contactThreadFunnelType.id === funnel.id)

      if ((!isCurrentOpen && isTargetExist) || isCurrentOpen) {
        try {
          await this.showAlert('warning', this.$i18n.t('AreYouSureToCloseStatus'), this.$i18n.t(
              isTargetExist?.status === 'PAUSED' ? 'FromOpenToPaused' : 'ContinueFunnelProcessButtonTooltip',
          ))
        } catch (err) { return }

        try {
          console.log('THIS LOG')
          // await this.changeCloseStatus(false, true, isTargetExist.id)
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }

        return
      }

      // create when (current is closed and target NOT exists)
      if ((!isCurrentOpen && !isTargetExist) || ['OPEN', 'PAUSED'].includes(this.thread.contactThreadFunnelProcessLast?.status)) {
        // Show second alert
        try {
          await this.showAlert('warning', this.$i18n.t('AreYouSureToCloseStatus'), this.$i18n.t(['OPEN', 'PAUSED'].includes(this.thread.contactThreadFunnelProcessLast?.status) ? 'OpenFunnelProcessButtonTooltipAndPauseCurrent' : 'OpenFunnelProcessButtonTooltip'))
        } catch (err) { return }

        // Change Active Funnel
        try {
          const payload = {
            contactThreadFunnelTypePoint: this.funnelList.find(a => a.id === funnel.id).contactThreadFunnelTypePoints[0].id.toString(),
            status: 'OPEN',
          }

          await this.addThreadFunnelProcess(payload)
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }
      }
    },
  },
}
</script>
