<template>
  <div>
    <applications
      :contact="{ ...contact, contactThread: thread }"
    />
  </div>
</template>

<script>
import Applications from '@/pages/applications/Applications.vue'
import { mapGetters } from 'vuex'

export default {
  components: { Applications },
  computed: {
    ...mapGetters({
      contact: 'contact/contact',
      thread: 'contact/thread',
    }),
  },
}
</script>
