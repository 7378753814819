<template>
  <expand-window
    :title="$t('FullWindow')"
  >
    <schedules
      :allow-filters="false"
      :thread-id="getObjectId(thread)"
      :contact-id="thread.contact.id"
    />
  </expand-window>
</template>

<script>
import ExpandWindow from '@core/components/@sw/ExpandWindow.vue'
import Schedules from '@/pages/Schedules.vue'

export default {
  components: { Schedules, ExpandWindow },
  props: {
    thread: {
      default: null,
    },
  },
}
</script>
