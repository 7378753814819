<template>
  <div
    class="d-flex flex-column flex-lg-row max-h-md-view"
    style="row-gap: 2rem; column-gap: 1rem;"
  >
    <div
      class="overflow-y-md-auto rounded-lg"
      style="min-width: 24rem; max-width: 100%; min-height: 30rem"
    >
      <contact-info />

      <app-collapse type="margin">
        <contact-details />

        <note />

        <thread-list />

        <thread-assigned-list />
      </app-collapse>
    </div>

    <div class="flex-grow-1 overflow-y-md-auto rounded-lg">
      <right-toolbar />

      <funnel-points-breadcrumbs />

      <thread-tabs class="mt-50" />
    </div>
  </div>
</template>

<script>
import { LOAD_CURRENT_CONTACT } from '@/@constants/mutations'
import { mapActions } from 'vuex'
import router from '@/router'
import ThreadTabs from './components/ThreadTabs.vue'
import FunnelPointsBreadcrumbs from './components/FunnelPointsBreadcrumbs.vue'
import ContactDetails from './components/ContactDetails.vue'
import ThreadList from './components/ThreadList.vue'
import ThreadAssignedList from './components/ThreadAssignedList.vue'
import RightToolbar from './components/RightToolbar.vue'
import ContactInfo from './components/ContactInfo.vue'
import Note from './components/Note.vue'

export default {
  components: {
    FunnelPointsBreadcrumbs,
    ContactInfo,
    ThreadTabs,
    Note,
    ContactDetails,
    ThreadList,
    ThreadAssignedList,
    RightToolbar,
  },
  data: () => ({
    loading: false,

    router,
  }),
  watch: {
    async $route(to, from) {
      if (to.params.contactId !== from.params.contactId) await this.loadCurrentContact()
    },
  },
  async mounted() {
    this.loading = true

    try {
      await this.loadCurrentContact()
    } catch (err) {
      this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)

      await this.router.push({ name: 'contacts' })
    }

    this.loading = false
  },
  methods: {
    ...mapActions({
      loadCurrentContact: `contact/${LOAD_CURRENT_CONTACT}`,
    }),
  },
}
</script>
