<!--eslint-disable-->

<template>
  <b-popover
    v-if="target"
    :key="target.id"
    ref="user_arrears_popover"
    :target="`user_arrears_${target.id}`"
    :container="container"
    triggers="hover"
    no-fade
    custom-class="px-0:popover popover-tasks"
  >
    <template #title>
      <span>
        {{ $t('funnel.OverdueTasks') }}
      </span>
    </template>

    <div
      v-if="target.contactThreadTasksOpened && !target.contactThreadTasksOpened.length"
      class="text-center"
    >
      <feather-icon
        icon="CheckSquareIcon"
        class="text-success"
        style="margin: 3px auto 5px"
        size="20"
      />
      <div class="px-1">
        {{ $t('funnel.NoArrears') }}
      </div>
    </div>
    <div v-else>
      <b-badge
        variant="primary"
        class="d-flex justify-content-between align-items-center mx-50"
        style="margin-top: -5px"
        :to="`/contact/${target.contact.id}/thread/${target.id}/tasks`"
      >
        {{ $t('ShowAll') }}
        <b-badge
          variant="light"
        >
          <span class="text-primary">
            {{ target.contactThreadTasksOpenedTotalItemCount }}
          </span>
        </b-badge>
      </b-badge>
      <b-list-group flush>
        <b-list-group-item
          v-for="(item, index) in target.contactThreadTasksOpened"
          :key="`user_${target.id}_task_${index}`"
          class="cursor-pointer"
          @click="openModal('viewModal', $t('PreviewTask'), '', item)"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <h5>
                <feather-icon
                  v-if="item.outdated"
                  v-b-tooltip
                  class="text-danger"
                  icon="AlertCircleIcon"
                  :title="$t('funnel.OverdueTask')"
                />
                {{ item.contactThreadTaskType.name }}
              </h5>
              <small class="d-flex align-items-center">
                {{ item.endAt.date.split('.')[0] }}
              </small>
            </div>
            <b-button-group
              v-if="checkRequiredPermissions([
                $roles.CONTACT_THREAD_TASK_CHANGE_STATUS_ALL,
                $roles.CONTACT_THREAD_TASK_CHANGE_STATUS_ASSIGNED,
              ])"
              class="ml-3 d-flex flex-md-nowrap"
              size="sm"
            >
              <b-button
                v-b-tooltip
                variant="outline-success"
                :title="$t('task.status.D')"
                @click.stop="changeTaskStatus(item.id, 'D', index, item)"
                class="px-50"
              >
                <sw-icon icon="CheckIcon" />
              </b-button>
              <b-button
                v-b-tooltip
                variant="outline-danger"
                :title="$t('task.status.ND')"
                @click.stop="changeTaskStatus(item.id, 'ND', index, item)"
                class="px-50"
              >
                <sw-icon icon="XIcon" />
              </b-button>
            </b-button-group>
          </div>
          <div class="mt-25">
            <small v-if="item.content">{{ item.content | truncate(100, '...') }}</small>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>

    <b-button
      v-if="checkRequiredPermissions([
        $roles.CONTACT_THREAD_TASK_ADD,
        { role: $roles.CONTACT_THREAD_ASSIGNED_TASK_ADD, data: target, userId: currentUser.id, field: 'assignedUsers' },
      ])"
      variant="flat-primary"
      class="pt-100 pb-50 w-100 rounded-0 pl-1 mt-1 d-flex align-items-center"
      style="border-top: 1px solid #d8d6de"
      @click="openModal('task', $t('NewTask'), $t('AddTask'))"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
      {{ $t('AddTask') }}
    </b-button>
  </b-popover>
</template>

<script>
/* eslint-disable */

import {BButtonGroup, BPopover, VBTooltip} from 'bootstrap-vue'
import { OPEN_MODAL, UPDATE_CONTACT_TASK } from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import * as roles from '@/helpers/permissions'
import EventBus from '@/event-bus'
import {eChangeThreadTaskStatus} from '@/@types/events'

export default {
  name: 'UserArrearsPopover',
  components: {
    BPopover,
    BButtonGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    roles,
  }),
  computed: {
    ...mapGetters({
      currentUser: 'auth/getCurrentUser',
    }),
  },
  props: ['container', 'target'],
  methods: {
    openModal(modalType = 'note', modalTitle = 'Nowa Notatka', okButtonTitle = 'Dodaj notatkę', data) {
      this.$root.$emit('bv::hide::popover')
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType,
          modalTitle,
          okButtonTitle,
          threadFor: this.target.id,
          data,
        })
    },
    changeTaskStatus(taskId, statusValue, taskIndex, item) {
      const data = {
        id: taskId.toString(),
        status: statusValue,
      }
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          if (item.contactThreadTaskType.isReportRequired) {
            this.$store.commit(`modal/${OPEN_MODAL}`,
              {
                modalType: 'report',
                modalTitle: this.$i18n.t('NewReport'),
                okButtonTitle: this.$i18n.t('AddReport'),
                threadFor: this.target.id,
                data: { ...item, statusValue, taskIndex },
              })
          } else {
            this.$store.dispatch(`singleContact/${UPDATE_CONTACT_TASK}`, { updateTaskData: data, threadId: this.target.id })
              .then(() => {
                EventBus.$emit(eChangeThreadTaskStatus, { threadId: this.target.id })
                this.target.contactThreadTasksOpened.splice(taskIndex, 1)
                this.showToast('success', this.$i18n.t('StatusUpdated'))
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
              })
          }
        })
    },
  },
}
</script>

<style>

</style>
