<template>
  <div
    class="d-flex flex-row align-items-center justify-content-between"
    style="row-gap: .18rem; column-gap: .6rem;"
  >
    <div
      class="d-flex flex-row align-items-start"
      style="row-gap: .18rem; column-gap: .6rem;"
    >
      <sw-icon
        v-if="icon"
        :icon="icon"
        size="14"
        class="text-secondary"
        style="margin-top: -.05rem"
      />

      <div class="d-flex flex-column flex-grow-1 w-100">
        <small class="text-secondary mb-0">
          {{ title }}
        </small>

        <!--    START::Content    -->
        <div v-if="$slots.default">
          <slot />
        </div>

        <strong
          v-else-if="content"
          class="font-small-3 text-wrap position-relative"
          :style="wrapLimit && `max-width: ${wrapLimit}px`"
          :class="{ 'cursor-pointer text-primary': actionClick }"
          @click.stop="actionClick && $emit('click', { id, key: itemKey })"
        >
          <b-badge
            v-if="type === 'BADGE'"
            variant="primary"
          >
            {{ content }}
          </b-badge>

          <b-form-checkbox
            v-else-if="type === 'CHECKBOX'"
            disabled
            :checked="content"
          />

          <template v-else>
            {{ content }}
          </template>
        </strong>

        <sw-icon
          v-else
          icon="MinusIcon"
        />
        <!--    END::Content    -->
      </div>
    </div>

    <!--    START::Actions    -->
    <b-button-group
      size="sm"
    >
      <slot name="buttons" />

      <!--    START::Call Phone    -->
      <b-button
        v-if="actionCall"
        v-b-tooltip.hover.v-primary
        :href="`tel:${content}`"
        variant="flat-primary"
        class="btn-icon p-25 rounded"
        :title="$t('MakeCall')"
      >
        <feather-icon icon="PhoneIcon" />
      </b-button>
      <!--    END::Call Phone    -->

      <!--    START::Send Email    -->
      <b-button
        v-if="actionEmail"
        v-b-tooltip.hover.v-primary
        :href="`mailto:${content}`"
        variant="flat-primary"
        class="btn-icon p-25 rounded"
        :title="$t('SendMessage')"
      >
        <feather-icon icon="MailIcon" />
      </b-button>
      <!--    END::Send Email    -->

      <!--    START::Copy    -->
      <b-button
        v-if="actionCopy"
        v-b-tooltip.hover.v-primary
        variant="flat-primary"
        class="btn-icon p-25 rounded"
        :title="$t('Copy')"
        @click.stop="copy(content)"
      >
        <feather-icon icon="CopyIcon" />
      </b-button>
      <!--    END::Copy    -->

      <!--    START::Edit    -->
      <b-button
        v-if="actionEdit"
        v-b-tooltip.hover.v-primary
        variant="flat-primary"
        class="btn-icon p-25 rounded"
        :title="$t('Edit')"
        @click.stop="$emit('edit', { id, key: itemKey })"
      >
        <feather-icon icon="EditIcon" />
      </b-button>
      <!--    END::Edit    -->

      <!--    START::Remove    -->
      <b-button
        v-if="actionRemove"
        v-b-tooltip.hover.v-danger
        variant="flat-danger"
        class="btn-icon p-25 rounded"
        :title="$t('Delete')"
        @click.stop="$emit('remove', { id, key: itemKey })"
      >
        <feather-icon icon="TrashIcon" />
      </b-button>
      <!--    END::Remove    -->

      <!--    START::Dropdown    -->
      <b-dropdown
        v-if="actionDropdown && $slots.dropdown"
        no-caret
        right
        variant="flat"
        size="sm"
        toggle-class="p-0"
      >
        <template #button-content>
          <b-button
            variant="flat-primary"
            size="sm"
            class="btn-icon p-25"
          >
            <sw-icon
              icon="MoreHorizontalIcon"
              size="16rem"
            />
          </b-button>
        </template>
        <slot name="dropdown" />
      </b-dropdown>
      <!--    END::Dropdown    -->
    </b-button-group>
    <!--    END::Actions    -->
  </div>
</template>

<script>
import { BButtonGroup, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: { BButtonGroup },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    icon: {
      type: String,
      required: false,
      default: 'CircleIcon',
    },
    title: {
      type: [String, Object],
      required: true,
    },
    content: {
      type: [String, Object, Array],
      required: false,
      default: '',
    },
    wrapLimit: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
    itemKey: {
      type: [String, Number, Object],
      required: false,
      default: '',
    },
    type: {
      type: String,
      required: false,
      default: 'TEXT',
    },
    actionClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionCall: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionEmail: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionCopy: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionRemove: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionDropdown: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['click', 'change', 'remove', 'edit', 'dropdown'],
  methods: {
    copy(txt) {
      this.$copyText(txt, this.$refs.contact_container).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
      }, e => {
        this.showToast('danger', e)
      })
    },
  },
}
</script>
