<template>
  <expand-window
    :title="$t('FullWindow')"
  >
    <offers :thread-id="getObjectId(thread)" />
  </expand-window>
</template>

<script>
import ExpandWindow from '@core/components/@sw/ExpandWindow.vue'
import Offers from '../../../views/core/singleContact/tabs/Offer.vue'

export default {
  components: { ExpandWindow, Offers },
  props: {
    thread: {
      default: null,
    },
  },
}
</script>
